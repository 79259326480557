import { upperCase } from 'lodash';
export default ({ app }, inject) => {

    inject('isSweden', isSweden);
    inject('isNorway', isNorway);

    const isDebug = () => {
        return upperCase(process.env.APP_DEBUG) === 'TRUE';
    };
    inject('isDebug', isDebug);

    const isProduction = () => {
        return !isDebug();
    };
    inject('isProduction', isProduction);

    const timestamp = (dateString) => {
        let timestamp = dateString.split('T')[0];
        return timestamp;
    };
    inject('timestamp', timestamp);


    const truncateString = (string, maxChars = 250) => {
        if (maxChars > string.length) {
            return string.substring(0, maxChars);
        }
        return string.substring(0, maxChars) + ' [...]';
    };
    inject('truncateString', truncateString);

    const formatSliceType = (type) => {
        if (type.includes('_')) {
            let split = type.split('_');
            for (let i = 0; i < split.length; i++) {
                split[i] = split[i].charAt(0).toUpperCase() + split[i].slice(1);
            }
            return split.join('');
        }

        return type;
    };
    inject('formatSliceType', formatSliceType);

    const formatBoolean = (bool) => {
        if (bool) {
            return app.$translate('yes', 'Ja'); 
        }
        return app.$translate('no', 'Nej');
    };
    inject('formatBoolean', formatBoolean);

    const formatPhoneNumber = (phoneNumber) => {
        if (isNorway()) {
            if (phoneNumber.startsWith('47')) {
                return phoneNumber;
            }
            if (phoneNumber.startsWith('0047')) {
                return phoneNumber.replace('0047', '47');
            }
            return `47${phoneNumber}`;
        }
        return phoneNumber;
    }
    inject('formatPhoneNumber', formatPhoneNumber);

    const validatePhoneNumber = (input) => {
        const phoneNumber = `${input}`;

        const reg = new RegExp('^[0-9]+$');
        if (!reg.test(phoneNumber)) {
            return false;
        }

        if (isNorway()) {
            return (
                (phoneNumber.startsWith('4') && phoneNumber.length === 8) ||
                (phoneNumber.startsWith('9') && phoneNumber.length === 8) ||
                (phoneNumber.startsWith('47') && phoneNumber.length === 10) ||
                (phoneNumber.startsWith('0047') && phoneNumber.length === 12) 
            );
        }

        return (
            (phoneNumber.startsWith('07') && phoneNumber.length === 10) ||
            (phoneNumber.startsWith('46') && phoneNumber.length === 11) ||
            (phoneNumber.startsWith('0046') && phoneNumber.length === 13)
        );
        
    };
    inject('validatePhoneNumber', validatePhoneNumber);

    const validateText = (textElement) => {
        if (typeof(textElement) === 'string') {
            return Boolean(textElement?.trim());
        }
        return Boolean(app.$prismic.asText(textElement)?.trim());
    };
    inject('validateText', validateText);

    inject('getCategoryData', (card, categoryKey) => {
        categoryKey = categoryKey ?? 'general';
        const generalData = card.data.category_data
            .find(category => category.key === 'general');
        
        const categoryData = card.data.category_data
            .find(category => category.key === categoryKey);
        
        const getValue = (dataKey) => {
            if (categoryData && validateText(categoryData[dataKey])) {
                return categoryData[dataKey];
            }
            if (generalData && validateText(generalData[dataKey])) {
                return generalData[dataKey];
            }
            return null;
        };

        return {
            // eslint-disable-next-line 
            usp_label: getValue('usp_label'),
            pros: getValue('pros'),
            cons: getValue('cons'),
            // eslint-disable-next-line 
            usp_box_title: getValue('usp_box_title'),
            // eslint-disable-next-line 
            usp_box_description: getValue('usp_box_description'),
        };
    });
    const getCardTypeString = (cardType) => {
        if (cardType === 'credit') {
            return app.$translate('credit_card', 'Kreditkort');
        } 
        else if (cardType === 'debit') {
            return app.$translate('debit_card', 'Debitkort');
        }
        else if (cardType === 'aggregator') {
            return app.$translate('aggregator_card', 'Prepaid');
        }
        return 'Unknown';
    };
    inject('getCardTypeString', getCardTypeString);

};

const getPrismicLocale = () => {
    return process.env.PRISMIC_LOCALE;
};

const isSweden = () => {
    return getPrismicLocale() === 'sv-se';
};
const isNorway = () => {
    return getPrismicLocale() === 'no';
};