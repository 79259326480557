// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-57080896]{font-family:\"Open Sans\"}.font-header[data-v-57080896]{font-family:\"Hero\"}.newsletter-form[data-v-57080896]{max-width:500px;padding:40px 20px;margin:0 auto;text-align:center}.newsletter-form .form-container[data-v-57080896]{margin-top:15px;grid-gap:15px;gap:15px}.newsletter-form .disclaimer[data-v-57080896]{margin-top:15px;font-size:12px;font-weight:lighter;color:#8c98a4;line-height:1rem}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
