// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-235c36dc]{font-family:\"Open Sans\"}.font-header[data-v-235c36dc]{font-family:\"Hero\"}.section-header[data-v-235c36dc]{width:100%;box-sizing:border-box}@media screen and (max-width:600px){.section-header[data-v-235c36dc]{margin-bottom:24px}}@media screen and (min-width:600px){.section-header[data-v-235c36dc]{margin-bottom:24px;margin-bottom:30px}}.section-header__teaser[data-v-235c36dc]{box-sizing:border-box}@media screen and (max-width:600px){.section-header__teaser[data-v-235c36dc]{margin-top:16px}}@media screen and (min-width:600px){.section-header__teaser[data-v-235c36dc]{margin-top:16px;margin-top:20px}}.section-header.left[data-v-235c36dc]{text-align:left}.section-header.right[data-v-235c36dc]{text-align:right}.section-header.center[data-v-235c36dc]{text-align:center}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
