<template>
    <transition name="compare">
        <div
            v-if="show"
            class="compare-cards"
        >
            <ul>
                <li
                    v-for="(id, index) in addedCards"
                    :key="index"
                >
                    <nuxt-link :to="$prismic.linkResolver(card(id))">
                        <prismic-image
                            :img="card(id).data.image"
                            w="40"
                            h="25"
                        />
                        <span>{{ card(id).data.title }}</span>
                    </nuxt-link>

                    <i 
                        class="fa-regular fa-xmark fa-clear"
                        @click="removeCard(id)"
                    />
                </li>
            </ul>

            <nuxt-link
                :to="getCompareRoute()"
                class="btn btn-sm btn-green"
            >
                {{ $translate('card_comparison_sticky_button_text', 'Jämför kort') }}
            </nuxt-link>
        </div>
    </transition>
</template>

<script>
export default {
    computed: {
        show() {
            return this.$store.state.cardsToCompare.length;
        },
        addedCards() {
            return this.$store.state.cardsToCompare;
        }
    },
    methods: {
        card(id) {
            return this.$store.state.cards[id];
        },
        removeCard(id) {
            this.$store.commit('REMOVE_CARD_FROM_COMPARE', id);
        },
        getCompareRoute() {
            // TODO: hard coded compare urls
            const ids = Object.values(this.$store.state.cardsToCompare);
            return `${this.$isSweden() ? '/jamfor/' : '/sammenlign/'}?card=${ids.join('&card=')}`;
        }
    }
};
</script>

<style lang="scss" scoped>
    .compare-cards {
        position: fixed;
        bottom: 0;
        right: 0;
        width: 300px;
        max-width: 100%;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        @include border-radius(10px,(top-left));
        background: $white;
        @include border;
        @include spacing(padding,6);
        @include grid(1,10px);

        ul {
            li {
                padding: 5px 0;
                @include flex(between);
                @include font-size(13px,6px);
                @include border(bottom);
                &:last-child { border-bottom: 0; }

                a {
                    @include flex(between);
                    &:hover {
                        text-decoration: underline;
                        color: $green;
                    }
                    img { @include border-radius(3px); }
                    span {
                        flex: 1;
                        padding-left: 10px;
                    }
                }

                i {
                    font-size: 18px;
                    cursor: pointer;
                }
            }
        }
    }

    .compare-enter-active,
    .compare-leave-active {
        transition: all .3s;
    }
    .compare-enter,
    .compare-leave-to {
        transform: translateX(300px);
    }
</style>
