import Vue from 'vue';

import {
    Section,
    PrismicImage,
    ButtonNuxt,
    Button
} from '@swegaming-ab/vue-components';

import ButtonTarget from '@/components/buttons/ButtonTarget.vue';
import SectionHeader from '@/components/SectionHeader.vue';

const components = {
    Section,
    ButtonTarget,
    Button,
    ButtonNuxt,
    PrismicImage,
    SectionHeader
};

Object.entries(components).forEach(([name, component]) => {
    Vue.component(name, component);
});
