<template>
    <Section
        width="md"
        class="banner"
    >
        <div class="container">
            <span class="font-header">404</span>
            <h1>{{ $translate('404_page_h1', 'Sidan kunde inte hittas ...') }}</h1>
            <p>{{ $translate('404_page_p', 'Sidan du försökte nå kan ha blivit flyttad eller borttagen.') }}</p>
            <nuxt-link
                to="/"
                class="btn btn-md btn-green"
            >
                {{ $translate('404_page_link_to_home_page', 'Gå till startsidan') }}
            </nuxt-link>
        </div>
    </Section>
</template>

<script>
export default {
    layout: 'site',
    head() {
        return {
            title: '404'
        };
    }
};
</script>

<style lang="scss" scoped>
    section::v-deep {
        min-height: calc(100vh - 600px);

        .section-inner {
            @include flex(start);

            .container {
                @include grid(1,30px);

                span {
                    color: $purple;
                    font-size: 92px;
                }

                .btn { max-width: 250px; }
            }
        }
    }
</style>
