<template>
    <a v-if="url" :href="url">
        <i :class="icon" />
        <span>{{ text }}</span>
    </a>
</template>

<script>
export default {
    props: {
        url: {
            type: String,
            required: false,
            default: ''
        },
        icon: {
            type: String,
            required: false,
            default: ''
        },
        text: {
            type: String,
            required: false,
            default: ''
        }
    }
};
</script>