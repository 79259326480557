// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-26bd6faf]{font-family:\"Open Sans\"}.font-header[data-v-26bd6faf]{font-family:\"Hero\"}.nav-search[data-v-26bd6faf]{position:relative}.nav-search i[data-v-26bd6faf]{cursor:pointer;font-size:24px;color:#01685e}.nav-search__input[data-v-26bd6faf]{position:absolute;z-index:9;right:calc(100% + 10px);top:45%;transform:translateY(-50%);width:150px;font-size:14px}@media screen and (min-width:900px){.nav-search__input[data-v-26bd6faf]{width:400px;right:0;top:270%}}.nav-search__result[data-v-26bd6faf]{position:fixed;top:calc(5% + 16px);z-index:99;right:0;background:#fff;width:100vw;max-height:70vh;overflow:auto;font-size:14px;border-radius:2px;box-shadow:0 10px 15px rgba(169,177,200,.25)}@media screen and (min-width:900px){.nav-search__result[data-v-26bd6faf]{position:absolute;right:0;top:364%;width:400px}}.nav-search__result li[data-v-26bd6faf]{padding:10px;border:0;box-sizing:border-box;border-top:1px solid rgba(241,214,245,.7)}.nav-search__result li[data-v-26bd6faf],.nav-search__result li a[data-v-26bd6faf]{display:flex;flex-wrap:wrap;justify-content:flex-start;align-items:center}.nav-search__result li a[data-v-26bd6faf]{width:100%}.nav-search__result li a[data-v-26bd6faf]:hover{text-decoration:underline;color:#01685e}.nav-search__result li a span[data-v-26bd6faf]{flex:1}.nav-search__result li a img[data-v-26bd6faf]{margin-right:10px;border-radius:3px}.nav-search__overlay[data-v-26bd6faf]{background:#000;opacity:.25;width:100vw;height:calc(100vh - 63px);position:fixed;left:0;top:63px}@media screen and (max-width:900px){.nav-search__overlay[data-v-26bd6faf]{top:60px}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
