<template>
    <nuxt-link :to="$prismic.linkResolver(link)">
        {{ linkText }}
    </nuxt-link>
</template>

<script>
export default {
    props: {
        link: {
            type: Object,
            required: true
        },
        linkText: {
            type: String,
            required: true
        }
    }
};
</script>