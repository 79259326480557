export default {
    props: {
        document: {
            type: Object,
            required: false,
            default: null
        },
        sliceOpen: {
            type: String,
            required: false,
            default: ''
        },
        breakpoint: {
            type: Number,
            required: false,
            default: 900
        }
    },
    data() {
        return {
            show: false,
            currentChildSliceOpen: '',
            mobileDropdownToggled: false
        };
    },
    watch: {
        currentSliceOpen() {
            if (this.show && this.sliceOpen !== this.document.id) {
                this.show = false;
            }
        },
        '$route'() {
            this.show = false;
            this.mobileDropdownToggled = false;
        },
        mobileDropdownToggled() {
            if (!this.mobileDropdownToggled) {
                this.currentChildSliceOpen = '';
            }
        }
    },
    computed: {
        currentSliceOpen() {
            return this.sliceOpen;
        }
    },
    methods: {
        updateSliceId(value) {
            this.currentChildSliceOpen = value;
        },
        onEnter() {
            if (window.innerWidth > this.breakpoint) {
                this.show = true;
            }
        },
        onLeave() {
            if (window.innerWidth > this.breakpoint) {
                this.show = false;
            }
        },
        toggle(emitValue) {
            this.show = ! this.show;
            if (this.show) {
                return this.$emit(emitValue, this.document.id);
            }
        },
    }
};