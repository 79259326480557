<template>
    <nav class="nav">
        <div class="nav-logo">
            <nuxt-link class="nav-logo__text" to="/">
                Kortio
            </nuxt-link>
        </div>

        <ul class="nav-list">
            <li
                v-for="(item, index) in navigationBody"
                :key="`item${index}`"
                class="nav-list-item"
            >
                <dropdown-menu
                    v-if="item.slice_type === 'dropdown_menu'"
                    :document="item"
                    :class="{show: mobileDropdownToggled}"
                    :slice-open="currentChildSliceOpen"
                    @expandedSlice="updateSliceId"
                />

                <mega-menu
                    v-else-if="item.slice_type === 'mega_menu'" 
                    :document="item"
                    :slice-open="currentChildSliceOpen"
                    :class="{show: mobileDropdownToggled}"
                    @expandedSlice="updateSliceId"
                />
                
                <nav-link
                    v-else
                    :link="item.primary.sl_link"
                    :link-text="item.primary.sl_text"
                    :class="[{show: mobileDropdownToggled}, 'nav-list-item__link']"
                />
            </li>
        </ul>

        <nav-search />
        <div class="nav-toggle">
            <i
                class="nav-toggle__btn"
                :class="{open: mobileDropdownToggled}"
                @click="mobileDropdownToggled =! mobileDropdownToggled"
            />
        </div>
    </nav>
</template>

<script>
import MegaMenu from '@/components/includes/components/MegaMenu.vue';
import ExpandDropdownLogic from '@/components/includes/mixins/ExpandDropdownLogic.js';
import DropdownMenu from '@/components/includes/components/DropdownMenu.vue';
import NavLink from '@/components/includes/components/NavLink.vue';
import NavSearch from '@/components/NavSearch.vue';
export default {
    components: {
        MegaMenu,
        DropdownMenu,
        NavLink,
        NavSearch
    },
    mixins: [
        ExpandDropdownLogic
    ],
    props: {
        navigationBody: {
            type: Array, 
            required: true
        }
    }
};
</script>