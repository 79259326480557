// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-525efbc6]{font-family:\"Open Sans\"}.font-header[data-v-525efbc6]{font-family:\"Hero\"}section[data-v-525efbc6]{min-height:calc(100vh - 600px)}section[data-v-525efbc6] .section-inner{display:flex;flex-wrap:wrap;justify-content:flex-start;align-items:center}section[data-v-525efbc6] .section-inner .container{display:grid;grid-gap:30px;gap:30px;grid-template-columns:repeat(1,1fr)}section[data-v-525efbc6] .section-inner .container span{color:#9529a3;font-size:92px}section[data-v-525efbc6] .section-inner .container .btn{max-width:250px}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
