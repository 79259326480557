<template>
    <div 
        class="nav-list-item__dropdown" 
        :class="{active: show}"
        @mouseenter="onEnter()"
        @mouseleave="onLeave()"
    >
        <span class="ddm_text" @click="toggle('expandedSlice')">{{ dropdownText }}</span>
        <ul
            v-show="show"
            class="dropdown"
        >
            <li
                v-for="(link, linkIndex) in document.items"
                :key="`linkDropdown${linkIndex}`"
                :class="{ child: link.is_child }"
            >
                <nuxt-link :to="$prismic.linkResolver(link.ddm_link)">
                    <span>{{ link.ddm_link_text }}</span>
                </nuxt-link>
            </li>
        </ul>
    </div>
</template>

<script>
import ExpandDropdownLogic from '@/components/includes/mixins/ExpandDropdownLogic.js';
export default {
    mixins: [
        ExpandDropdownLogic
    ],
    computed: {
        dropdownText() {
            return this.document.primary.ddm_text;
        }
    }
};
</script>