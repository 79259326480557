
export default ({ app }, inject) => {
    const event = {
        applicationSubmitted: (document) => {
            app.$gtm.push({
                event: 'applicationSubmitted',
                card: document.data.title,
                isPartner: document.data.application_type !== 'no_partnership'
            });
        },
        questionSubmitted: () => {
            app.$gtm.push({
                event: 'questionSubmitted',
            });
        },
        ratingSubmitted: () => {
            app.$gtm.push({
                event: 'ratingSubmitted',
            });
        },
        compareCards: (ids) => {
            if (ids && ids.length > 0) {
                const names = ids.map(id => app.store.state.cards[id].data.title);
                app.$gtm.push({
                    event: 'compareCards',
                    cards: names
                });
            }
        },
        cardGuideSubmitted: () => {
            app.$gtm.push({
                event: 'cardGuideSubmitted',
            });
        },

        insurance: {
            formShown: () => {
                app.$gtm.push({
                    event: 'insuranceFormShown',
                });
            },
            added: () => {
                app.$gtm.push({
                    event: 'insuranceAdded',
                });
            },
            continued: () => {
                app.$gtm.push({
                    event: 'continuedWithoutInsurance',
                });
            },
        }
    };

    inject('event', event);
};