// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-4b88f329]{font-family:\"Open Sans\"}.font-header[data-v-4b88f329]{font-family:\"Hero\"}.compare-cards[data-v-4b88f329]{position:fixed;bottom:0;right:0;width:300px;max-width:100%;box-shadow:0 7px 29px 0 rgba(100,100,111,.2);border-top-left-radius:10px;background:#fff;border:0;border:1px solid rgba(241,214,245,.7);box-sizing:border-box;display:grid;grid-gap:10px;gap:10px;grid-template-columns:repeat(1,1fr)}@media screen and (max-width:600px){.compare-cards[data-v-4b88f329]{padding:24px}}@media screen and (min-width:600px){.compare-cards[data-v-4b88f329]{padding:24px}}@media screen and (min-width:900px){.compare-cards[data-v-4b88f329]{padding:30px}}.compare-cards ul li[data-v-4b88f329]{padding:5px 0;display:flex;flex-wrap:wrap;justify-content:space-between;align-items:center;font-size:13px;line-height:19px;border:0;box-sizing:border-box;border-bottom:1px solid rgba(241,214,245,.7)}.compare-cards ul li[data-v-4b88f329]:last-child{border-bottom:0}.compare-cards ul li a[data-v-4b88f329]{display:flex;flex-wrap:wrap;justify-content:space-between;align-items:center}.compare-cards ul li a[data-v-4b88f329]:hover{text-decoration:underline;color:#01685e}.compare-cards ul li a img[data-v-4b88f329]{border-radius:3px}.compare-cards ul li a span[data-v-4b88f329]{flex:1;padding-left:10px}.compare-cards ul li i[data-v-4b88f329]{font-size:18px;cursor:pointer}.compare-enter-active[data-v-4b88f329],.compare-leave-active[data-v-4b88f329]{transition:all .3s}.compare-enter[data-v-4b88f329],.compare-leave-to[data-v-4b88f329]{transform:translateX(300px)}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
