<template>
    <div class="nav-search">
        <i
            v-if="!open"
            class="fa-solid fa-magnifying-glass"
            @click="open = true"
        />

        <i
            v-else
            class="fa-regular fa-magnifying-glass-minus"
            @click="open = false"
        />

        <input 
            v-if="open"
            ref="input"
            v-model="query"
            class="nav-search__input"
            type="text" 
            :placeholder="$translate('search_cards', 'Sök efter kort')"
        >

        <ul 
            v-if="query.length > 2 && open"
            class="nav-search__result"
        >
            <li
                v-for="(card, index) in cardList"
                :key="index"
                @click="set(card.id)"
            >
                <nuxt-link :to="$prismic.linkResolver(card)">
                    <prismic-image
                        :img="card.data.image"
                        w="50"
                        h="30"
                    />
                    <span>{{ card.data.title }}</span>
                </nuxt-link>
            </li>
        </ul>

        <div
            v-if="open" 
            class="nav-search__overlay"
            @click="open = false"
        />
    </div>
</template>

<script>
export default {
    data() {
        return {
            open: false,
            query: '',
        };
    },
    computed: {
        cardList() {
            let cards = Object.values(this.$store.state.cards);
            return cards.filter(card => card.data.title.toLowerCase().includes(this.query.toLowerCase()));
        }
    },
    watch: {
        open() {
            if (this.open) {
                this.$nextTick(() => {
                    this.$refs.input.focus();
                });
            }
        },
        '$route'() {
            this.open = false;
            this.query = '';
        }
    },
    methods: {
        set(cardId) {
            console.log(cardId);
        }
    }
};
</script>

<style lang="scss" scoped>
    .nav-search {
        position: relative;
        

        i {
            cursor: pointer;
            font-size: 24px;
            color: $green;
        }

        &__input {
            position: absolute;
            z-index: 9;
            right: calc(100% + 10px);
            top: 45%;
            transform: translateY(-50%);
            width: 150px;
            font-size: 14px;
            @include device(desktop) {
                width: 400px;
                right: 0;
                top: 270%;
            }
        }

        &__result {
            position: fixed;
            top: calc(5% + 16px);
            z-index: 99;
            right: 0;
            background: $white;
            width: 100vw;
            max-height: 70vh;
            overflow: auto;
            font-size: 14px;
            border-radius: 2px;
            box-shadow: $bxs;
            @include device(desktop) {
                position: absolute;
                right: 0;
                top: 364%;
                width: 400px;
            }

            li {
                padding: 10px;
                @include flex(start);
                @include border(top);

                a { 
                    width: 100%;
                    @include flex(start); 
                    &:hover { 
                        text-decoration: underline;
                        color: $green;
                    }
                    span { flex: 1; }
                    img { 
                        margin-right: 10px;
                        @include border-radius(3px)
                    }
                }
            }
        }

        &__overlay {
            background: #000;
            opacity: .25;
            width: 100vw;
            height: calc(100vh - 63px);
            position: fixed;
            left: 0;
            top: 63px;

            @media screen and (max-width: 900px) {
                top: 60px;
            }   
        }
    }
</style>