<template>
    <div class="link-column col-12" :class="colSize">
        <div class="link-column-box">
            <div 
                v-if="subHeader" 
                class="link-column-sub-header" 
                :class="{active: show}"
                @click="toggle('expandedDropdown')"
            >
                <a class="letter-capitalize">
                    {{ subHeader }}
                </a>
                <i 
                    class="fas fa-chevron-right fa-1xs" 
                    :class="{ 'fa-rotate-90': show }"
                />
                <div class="sub-header-divider" />
            </div>
            <div 
                class="link-column-links"
                :class="[{ 'd-none': !show }, amountOfColumns > 1 ? gridRepeater : '']"    
            >
                <nuxt-link 
                    v-for="(item, index) in items" 
                    :key="index" 
                    :to="$prismic.linkResolver(item.link)"
                >
                    <span class="letter-capitalize">{{ item.link_text }}</span>
                </nuxt-link>
            </div>
        </div>
    </div>
</template>

<script>
import ExpandDropdownLogic from '@/components/includes/mixins/ExpandDropdownLogic.js';
export default {
    mixins: [
        ExpandDropdownLogic
    ],
    computed: {
        subHeader() {
            return this.document.primary.sub_header;
        },
        items() {
            return this.document.items.filter(item => item.link_text && item.link?.id);
        },
        colSize() {
            if (!this.amountOfColumns) {
                return 'col-lg-3';
            }
            return `col-lg-${(this.amountOfColumns * 3)}`;
        },
        gridRepeater() {
            return `grid-template-columns-${this.amountOfColumns}`;
        },
        amountOfColumns() {
            return this.document.primary.amount_of_columns;
        }
    }
};
</script>