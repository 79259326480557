<template>
    <div
        v-if="$validateText(title) || $validateText(teaser)"
        :id="id"
        class="section-header"
        :class="align"
    >
        <div
            v-if="$validateText(title)"
            class="section-header__title"
            v-html="$prismic.asHtml(title)"
        />

        <div
            v-if="$validateText(teaser)"
            class="section-header__teaser rich-text"
            v-html="$prismic.asHtml(teaser)"
        />
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: Array,
            required: true,
            default: () => {}
        },
        teaser: {
            type: Array,
            required: false,
            default: () => {}
        },
        align: {
            type: String,
            required: false,
            default: 'left'
        },
        id: {
            type: String,
            required: false,
            default: null
        }
    }
};
</script>

<style lang="scss" scoped>
    .section-header {
        width: 100%;
        @include spacing(margin,6,bottom);

        &__teaser {
            @include spacing(margin,4,top);
        }
    }

    .section-header.left { text-align: left; }
    .section-header.right { text-align: right; }
    .section-header.center { text-align: center; }
</style>
